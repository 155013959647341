@import "styles/default.scss";

.home-container {
  display: flex;
  flex: 1;

  .flow-icon {
    width: 15rem;
    margin-bottom: 5rem;

    &.mobile {
      width: 7.5rem;
      margin-bottom: unset;
    }
  }

  &.mobile {
    flex-direction: column;
  }
}

.common-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: auto;
  @include big-text;

  &.code-container {
    background: $primary-color;
  }

  &.film-container {
    background: $secondary-color;
  }
}
