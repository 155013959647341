HTML CSS JSResult Skip Results Iframe EDIT ON * {
  box-sizing: border-box;
}

.slider {
  width: 95%;
  text-align: center;
  overflow: hidden;

  flex: 1;
  display: flex;
  flex-direction: column;

  margin: 1rem;
}

.slides {
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */

  flex: 1;
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: black;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  margin-right: 50px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}
.slides > div:target {
  /*   transform: scale(0.8); */
}
.author-info {
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.75rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
.author-info a {
  color: white;
}
// img {
//   object-fit: cover;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }

.slider > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}
.slider > a:active {
  top: 1px;
}
.slider > a:focus {
  background: red;
}

/* Don't need button navigation */
// @supports (scroll-snap-type) {
//   .slider > a {
//     display: none;
//   }
// }

.dot-container {
  display: flex;
  justify-content: center;
}

.dot {
  height: 2rem;
  width: 2rem;
  //   background-color: #bbb;
  border: 1px yellow solid;
  border-radius: 50%;
  display: flex;
  margin: 0.5rem;
  align-items: center;
  justify-content: center;
}
