@import "styles/default.scss";

.port-card {
  margin: 0 10px;
  padding: 0;
  background-size: cover;
  position: relative;
  background: black;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin: 15px 10px;
  }

  .cover-image {
    width: 100%;
    filter: grayscale(100%);
    opacity: 0.8;
    display: block;
    width: 100%;
    height: auto;
    // transition: 0.5s ease;
    backface-visibility: hidden;
  }

  &:hover .cover-image {
    opacity: 0.2;
  }

  &:hover .overlay {
    opacity: 1;
  }

  .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .doodles {
    position: absolute;
    right: -1.5rem;
    top: -1.5rem;
    color: $accent-color;
    letter-spacing: 1rem;
    mix-blend-mode: multiply;
    font-family: "Nunito Sans";
    font-weight: 700;
    font-size: 50px;
  }

  .text {
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    position: absolute;
    bottom: 1rem;

    .wrap {
      display: flex;
      flex-direction: column;
    }

    .port-title {
      color: $accent-color;
      @include box-title;
    }

    .port-company,
    .port-details {
      color: $secondary-color;
      @include box-intro;
    }
  }
}
