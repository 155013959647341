@import "styles/default.scss";

.education-card {
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25rem;
  text-align: center;

  svg {
    width: 15rem;
  }
}

.education-title {
  @include box-title;
}

.education-date {
  @include p-text;
}
