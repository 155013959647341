@import "styles/default.scss";

.header-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 1rem 3rem;
  border-bottom: 1px solid;
  font-weight: 600;
  background: black;
  color: white;

  &.mobile {
    padding: 1rem;
    justify-content: space-around;
  }

  .flow-icon {
    position: absolute;
    right: 49%;
    top: 10%;
  }

  svg {
    width: 3rem;
  }
}

.logo {
  @include logo;

  &.mobile {
    font-size: 14px;
  }
}

.nav-container.desktop {
  display: flex;

  .nav-item {
    margin-left: 1rem;
    &:hover {
      text-decoration: $accent-color;
    }
    &:active {
      text-decoration: underline;
    }
  }
}

.column {
  width: 33%;
  text-align: center;

  &.icon {
    display: flex;
    justify-content: flex-end;

    align-items: center;
  }
}

.mobile-menu-container {
  // position: absolute;
  // display: flex;
  // flex: 1;
  background: red;

  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}
