@import "../../../styles/default.scss";

.mobile-exp-container {
  padding: 1rem;
  width: 80%;

  border: solid 1px white;

  text-align: left;
  overflow: auto;

  .mobile-title {
    @include p-title;
    margin: 0;

    &.role {
      color: $accent-color;
    }
  }

  .mobile-date,
  .mobile-content,
  .mobile-exp-type {
    @include p-text;
  }
  .mobile-skills {
    overflow-wrap: break-word;
    color: $accent-color;
    @include p-text;

    .skill {
      margin-right: 0.5rem;
    }
  }
}
