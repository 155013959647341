@import "../../../styles/default.scss";

.experience-container {
  width: 100%;
  display: flex;
  height: 100%;
}

.experience-section {
  background: $secondary-color;
  color: $primary-color;
  width: 65%;
  padding: 3rem;
}

.divider {
  height: 1px;
  border-width: 0;
  background: black;
  margin: 2rem auto;
}

.skills-section {
  padding: 3rem;
  width: 30%;

  .skills-cat {
    margin-bottom: 2rem;
  }

  .skills-title {
    @include p-title;
    color: $inactive-color;
    &:hover {
      color: $secondary-color;
    }
  }

  .skills-item {
    @include p-text;
    margin-right: 0.5rem;
    color: $inactive-color;
    &:hover {
      color: $accent-color;
    }
  }

  .hightlight {
    background: $accent-color;
  }
}
