@import "styles/default.scss";

.contact-container {
  background: $primary-color;
  display: flex;
  flex: 1;
}

.contact-form-container {
  width: 55%;
}

.contact-header {
  background: rgba(255, 255, 255, 0.9);
  @include big-text;
  font-size: 85px;
  position: absolute;
  top: 8rem;
  left: 5rem;
  padding: 1rem 2rem;
}

.contact-form {
  padding: 17rem 5rem;
  display: flex;
  flex-direction: column;
  width: 40%;
  position: absolute;

  .input {
    margin: 1rem 0;
    background: rgba(255, 255, 255, 0.9);
    font-family: inherit;
    font-size: 25px;
    font-weight: 700;
    padding: 1rem;
    text-transform: uppercase;
    border: 3px solid $primary-color;
    &:focus {
      outline-color: $accent-color;
    }
  }

  .area {
    height: 10rem;
  }
}

.map {
  width: 100%;
  //   background-image: url("https://instagram.ftxl2-1.fna.fbcdn.net/v/t51.2885-15/e35/75412641_169218744165357_273059470222604044_n.jpg?_nc_ht=instagram.ftxl2-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=1YhDwLzvm4IAX_KC2oy&oh=dd65da161c83d5616c9d70490ac3a52b&oe=5F242415");
  background: url("../../../assets/images/map.svg") center no-repeat;
  background-size: cover;
}

.mobile-contact-container {
  padding: 2rem 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  background: $primary-color;
  align-items: center;

  .mobile-contact-header {
    @include blog-title;
    font-size: 3rem;
    color: $accent-color;
    width: 80%;
  }

  .mobile-contact-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 80%;

    .text-inputs {
      display: flex;
      flex-direction: column;
      flex: 1;

      .input {
        margin: 0.5rem 0;
        background: rgba(255, 255, 255, 0.9);
        font-family: inherit;
        @include box-title;
        padding: 0.5rem;
        text-transform: uppercase;
        border: 3px solid $primary-color;
        &:focus {
          outline-color: $accent-color;
        }
      }

      .area {
        height: 8rem;
      }
    }
  }
}
