@import "../../styles/default.scss";

.flow-container {
  background: $primary-color;
  color: $secondary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.flow-menu {
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  padding: 3rem;
}

.menu-item {
  &:hover {
    color: $accent-color;
  }
}

.mobile-header {
  @include blog-title;
  margin-bottom: 0;
  letter-spacing: 8px;
}
