$primary-color: black;
$secondary-color: white;
$accent-color: #e5ed00;
$inactive-color: gray;

// FONTS
@mixin top-nav {
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 15px;
}

@mixin big-text {
  font-family: "Nunito Sans";
  font-weight: 800;
  font-size: 80px;
}

@mixin p-text {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 15px;
}

@mixin p-title {
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 25px;
}

@mixin blog-title {
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 30px;
}

@mixin blog-text {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
}


@mixin box-title {
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 20px;
}

@mixin box-intro {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 18px;
}

@mixin logo {
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 5px;
}

// SPACING
$standard-container-padding: 6rem;
$device-container-padding: 2rem;