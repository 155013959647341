@import "../../../styles/default.scss";

.education-container {
  width: 80vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.top-section {
  height: 100%;
}

.bottom-section {
  background: $accent-color;
  height: 100%;
  position: relative;
}

.group {
  margin: 0 10rem;
  display: flex;
  justify-content: space-around;
}

.divider {
  background: $accent-color;
}
