@import "styles/default.scss";

.exp-card {
  span {
    @include p-title;

  }
   
  p {
    @include p-text;
  }
 
  p.exp-type {
    font-size: 16px;
    margin-top: unset;
  }
}
