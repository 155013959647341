@import "../../../styles/default.scss";

.pinterest-container {
  margin: 0;
  padding: 0;
  width: 80vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-auto-rows: 14.5px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
}
