@import "src/styles/default.scss";

.openMenu {
  font-weight: 700;
  font-size: 1.5rem;
  // -webkit-transform: rotate(90deg);
  // -moz-transform: rotate(90deg);
  // -o-transform: rotate(90deg);
  // -ms-transform: rotate(90deg);
  // transform: rotate(90deg);
}

// .overLay {
//   display: flex;
//   flex-direction: column;
// }

.menu {
  z-index: 1;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 3rem 0;
  @include blog-text;
  letter-spacing: 10px;
}

.menuItems {
  display: flex;
  flex-direction: column;
}

.navItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item {
  margin: 0.5rem auto;

  &.active {
    color: $accent-color;
    text-decoration: line-through;
  }
}

.mobile-divider {
  margin: 1rem auto;
  height: 1px;
  border-width: 0;
  background: black;
}
